export default class test {
  constructor() {
    this.input = {
      id: null,
      name: "",
      powers: [],
    };
    this.body = [];
  }
  setInput(mode, data) {
    if (mode === "Сохранить") {
      this.input.id = data.id;
      this.input.name = data.name;
      this.input.powers = data.powers;
    }
    return this.input;
  }
  setBody(data) {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        this.body.push({
          fields: [
            {
              id: 1,
              name: data[i].id,
              type: "string",
            },
            {
              id: 2,
              name: data[i].name,
              type: "string",
            },
            {
              id: 5,
              name: data[i].createdAt.substr(0, 10),
              type: "string",
            },
          ],
        });
      }
    }
    return this.body;
  }
}
