<template>
  <div class="about">
    <loader v-if="loading"></loader>
    <table-component
      v-else
      :items="items"
      :edit="true"
      :delet="true"
      @save-button="saveButton()"
      @delete-button="deleteButton"
    ></table-component>
  </div>
</template>
<script>
import TableComponent from "@/components/TableComponent";
import Loader from "../components/Loader";
import role from "@/model/role.js";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    TableComponent,
    Loader,
  },
  data() {
    return {
      loading: true,
      items: {
        head: [
          { id: 1, name: "ID" },
          { id: 2, name: "Название" },
          { id: 5, name: "Создан" },
        ],
        body: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      ROLE: "Role/STATE",
    }),
  },
  async mounted() {
    await this.checkPower();
    await this.setBody();
  },
  methods: {
    ...mapActions({
      getRoles: "Role/GET_ALL_ROLES",
      deleteRole: "Role/DELETE_ROLES",
    }),
    checkPower() {
      let state = this.$power.check(this.$route.meta.power);
      if (state === false) {
        this.$router.push("/");
      }
    },
    async setBody() {
      this.loading = true;
      await this.getRoles();
      let model = new role();
      this.items.body = model.setBody(this.ROLE.allRoles);
      this.loading = false;
    },
    saveButton() {
      let model = new test();
      let result = model.setInput(this.input);
      this.items.body.push({
        id: this.items.body[this.items.body.length - 1].id + 1,
        fields: [
          { id: 1, name: result.one },
          { id: 2, name: result.two },
          { id: 3, name: result.three },
          { id: 4, name: result.four },
          { id: 5, name: result.five },
        ],
      });
    },
    async deleteButton(item) {
      this.loading = true;
      let errors = [];
      for (let i = 0; i < item.length; i++) {
        let response = await this.deleteRole(item[i].id);
        if (response.type !== "success") {
          errors.push(item[i].id);
        }
      }
      if (errors.length !== 0) {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: errors,
        });
      }
      await this.setBody();
      this.loading = false;
    },
  },
};
</script>
